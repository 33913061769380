import React from 'react';
import './SocialLink.scss';

const FACEBOOK_TYPE = 'facebook';
const TWITTER_TYPE = 'twitter';
const LINKEDIN_TYPE = 'linkedin';
const GOOGLE_PLUS_TYPE = 'google_plus';

export const SocialLink = (props) => {
  const {
    socialLink,
    theme = 'light',
    size = 'small',
  } = props;
  const {
    type,
    url,
  } = socialLink;
  const typeClass = type.replace(/_/g, '-');
  switch (type) {
    case FACEBOOK_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 7 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.513 16H4.62V7.996h2.085s.195-1.292.29-2.704H4.633V3.45c0-.275.338-.645.674-.645H7V0H4.697c-3.26 0-3.184 2.696-3.184 3.099v2.202H0v2.694h1.513V16z" fillRule="evenodd" />
          </svg>
        </a>
      );
    case TWITTER_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.954 4.569a10 10 0 0 1-2.825.775 4.958 4.958 0 0 0 2.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 0 0-8.384 4.482C7.691 8.094 4.066 6.13 1.64 3.161a4.822 4.822 0 0 0-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 0 1-2.228-.616v.061a4.923 4.923 0 0 0 3.946 4.827 4.996 4.996 0 0 1-2.212.085 4.937 4.937 0 0 0 4.604 3.417 9.868 9.868 0 0 1-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 0 0 7.557 2.209c9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63a9.936 9.936 0 0 0 2.46-2.548l-.047-.02z" />
          </svg>
        </a>
      );
    case LINKEDIN_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="evenodd">
              <path d="M.394 15.045h3.254V5.257H.394zM2 3.92h.021c1.135 0 1.84-.75 1.84-1.69-.02-.961-.705-1.692-1.818-1.692C.929.538.2 1.268.2 2.23.2 3.17.908 3.921 2 3.921zM8.703 15.045V9.58c0-.294.022-.585.107-.794.236-.585.77-1.19 1.67-1.19 1.178 0 1.648.898 1.648 2.213v5.237h3.255V9.432c0-3.006-1.606-4.405-3.747-4.405-1.73 0-2.501.951-2.933 1.617v.032H8.68l.022-.032V5.257H5.448c.043.918 0 9.788 0 9.788h3.255z" />
            </g>
          </svg>
        </a>
      );
    case GOOGLE_PLUS_TYPE:
      return (
        <a
          className={`social-link ${typeClass} ${theme} ${size}`}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <svg viewBox="0 0 22 14" xmlns="http://www.w3.org/2000/svg">
            <g fillRule="evenodd">
              <path d="M7.656 8.46h3.41a4.338 4.338 0 0 1-4.117 2.89 4.344 4.344 0 0 1-4.296-4.16 4.336 4.336 0 0 1 4.328-4.502c1.118 0 2.138.426 2.908 1.124a.47.47 0 0 0 .638-.003l1.253-1.178a.468.468 0 0 0 0-.68A6.956 6.956 0 0 0 7.12.038C3.271-.036.027 3.12 0 6.97a6.981 6.981 0 0 0 13.95.443c.006-.047.01-1.603.01-1.603H7.656a.468.468 0 0 0-.468.468v1.714c0 .259.21.468.468.468M20.045 5.955v-1.57A.384.384 0 0 0 19.661 4h-1.322a.384.384 0 0 0-.384.384v1.57h-1.57A.384.384 0 0 0 16 6.34v1.322c0 .212.172.384.384.384h1.57v1.57c0 .213.173.385.385.385h1.322a.384.384 0 0 0 .384-.384v-1.57h1.57A.384.384 0 0 0 22 7.66V6.339a.384.384 0 0 0-.384-.384h-1.57z" />
            </g>
          </svg>
        </a>
      );
    default:
      return null;
  }
};
