import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { SearchIcon } from './SearchIcon';
import './SearchForm.scss';

export default class SearchForm extends Component {
  state = { searchVal: null };

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscKey, false);
  }

  componentWillReceiveProps(nextProps) {
    const { active: wasActive } = this.props;
    const { active: isActive } = nextProps;
    if (!wasActive && isActive) {
      setTimeout(() => {
        if (this.searchInput) this.searchInput.focus();
      }, 100);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscKey, false);
  }

  handleEscKey = (event) => {
    const { toggleSearch, active } = this.props;
    if (event.keyCode === 27 && active) toggleSearch(event);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { toggleSearch } = this.props;
    const { searchVal } = this.state;
    if (!searchVal) return false;
    if (toggleSearch) toggleSearch();
    return navigate(`/search?query=${searchVal}&origin=header`);
  }

  onChangeHandler = (event) => {
    const { value: searchVal } = event.target;
    this.setState({ searchVal });
  }

  render() {
    const { active, toggleSearch } = this.props;
    const { searchVal } = this.state;
    if (!active) return <div className="search-form-block" />;
    return (
      <div className="search-form-block active">
        <div className="wrap">
          <form className="search-form" name="search-form" onSubmit={this.handleSubmit}>
            <input
              type="text"
              id="search"
              name="search"
              placeholder="Enter search phrase here..."
              ref={(c) => { this.searchInput = c; }}
              onChange={this.onChangeHandler}
              value={searchVal || ''}
              required
            />
            {searchVal && (
              <button type="submit" aria-label="Search" className={searchVal ? 'active' : 'not-active'}>
                <SearchIcon strokeColor="#817B71" />
              </button>
            )}
          </form>
          <button
            type="button"
            className="hamburger active"
            aria-label="Toggle Search"
            onClick={toggleSearch}
          />
        </div>
      </div>
    );
  }
}
