import React from 'react';
import { Link } from 'gatsby';
import './Logo.scss';

export const Logo = props => {
  const {
    colorClass,
    sizeClass
  } = props;
  return (
    <Link to="/" className={`logo ${colorClass || ''} ${sizeClass || ''}`} aria-label="Capital Partners" />
  );
}
