import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import OnVisible from 'react-on-visible';
import SubscribeForm from './SubscribeForm';
import { decodeEntities } from '../utils/htmlParse';
import { Logo } from './Logo';
import { SocialLink } from './SocialLink';
import { FooterNav } from './FooterNav';
import './Footer.scss';


class Footer extends Component {
  scrollToTop = event => {
    if (event) event.preventDefault();
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  render() {
    const { data, location = {} } = this.props;
    const isHomePage = location && location.pathname === '/';
    const isRiskAssessmentQuiz = location && /risk-insurance\/risk-assessment-quiz\/?$/.test(location.pathname);
    const {
      primaryNavigation,
      siteSettings,
      metaData
    } = data;
    const { url: baseUrl } = metaData;
    const { items: navItems } = primaryNavigation;
    const { options } = siteSettings;
    const {
      addressDisplay,
      socialLinks,
      url,
      disclaimer,
      abn,
      afsl,
      phoneNumber,
    } = options;
    const currentYear = (new Date()).getFullYear();
    return (
      <footer>
        <div className="scroll-top-anchor">
          <button
            type="button"
            className="scroll-top-button"
            onClick={event => this.scrollToTop(event)}
          >
            <span>Back to top</span>
          </button>
        </div>
        <div className="footer-right">
          {!isRiskAssessmentQuiz && <SubscribeForm />}
          <div className="scroll-top-anchor-tablet">
            <button
              type="button"
              className="scroll-top-button"
              onClick={event => this.scrollToTop(event)}
              aria-label="Back to top"
            >
              <span>Back to top</span>
            </button>
          </div>
          <div className="footer-main">
            <div className="wrap">
              <div className="top-row footer-row">
                <FooterNav
                  baseUrl={baseUrl}
                  navItems={navItems}
                />
              </div>
              <div className="middle-row footer-row">
                <Logo />
                <OnVisible className="contact-details-grid">
                  <div className="address" dangerouslySetInnerHTML={{ __html: addressDisplay }} />
                  <div className="contact-details">
                    <a href={`tel:${phoneNumber}`}>{`T ${phoneNumber}`}</a>
                    <a href={url}>{url.replace(/(^\w+:|^)\/\/(www\.)*/, '')}</a>
                  </div>
                  <div className="registrations">
                    <span>{`ABN ${abn}`}</span>
                    <span>{`AFSL ${afsl}`}</span>
                  </div>
                </OnVisible>
              </div>
              <div className="bottom-row footer-row">
                <ul className="social-links">
                  {socialLinks.map(item => (
                    <li key={item.type} className="social">
                      <SocialLink socialLink={item} size="small" theme="light" />
                    </li>
                  ))}
                </ul>
                <div className="disclaimers">
                  <p className="disclaimer">{decodeEntities(disclaimer)}</p>

                  <div className="copyright">
                    <span>{`© ${currentYear} Capital Partners Consulting Pty Ltd`}</span>
                    <span>All rights reserved</span>
                    <span>
                      Website by
                      {isHomePage ? (
                        <a href="https://www.birdbrain.com.au/" target="_blank" rel="noreferrer noopener">
                          BirdBrain
                        </a>
                      ) : <span> BirdBrain</span>}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        primaryNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "primary-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        },
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            addressDisplay,
            socialLinks {
              type,
              url
            },
            emailAddress,
            disclaimer,
            abn,
            afsl,
            phoneNumber,
            url
          }
        },
        metaData: wordpressSiteMetadata {
          url
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
