import React from 'react';
import { Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import './FooterNav.scss'

const NavItem = (props) => {
  const { item, baseUrl } = props;
  const {
    classes,
    slug,
    title,
    url,
    children
  } = item;
  const splat = url.replace(baseUrl, '');
  const isExternal = classes === 'external';
  return (
    <div
      key={slug}
      className={`nav-link ${classes} ${children ? 'parent-link' : ''}`}
    >
      {isExternal ? (
        <a href={url} aria-label={title} target="_blank" rel="noopener noreferrer">{decodeEntities(title)}</a>
      ) : (
        <Link to={splat} aria-label={decodeEntities(title)}>{decodeEntities(title)}</Link>
      )}
      { children && (
        <NavChildren baseUrl={baseUrl} childNav={children} />
      )}
    </div>
  );
}

const NavChildren = (props) => {
  const { childNav, baseUrl } = props;
  return (
    <ul className="nav-children">
      {childNav.map(child => {
        const {
          classes,
          slug,
          title,
          url
        } = child;
        const isExternal = classes === 'external';
        const splat = url.replace(baseUrl, '');
        return isExternal ? (
          <li key={slug} className={`nav-child ${classes}`}>
            <a href={url} aria-label={title} target="_blank" rel="noopener noreferrer">{decodeEntities(title)}</a>
          </li>
        ) : (
          <li key={slug} className={`nav-child ${classes}`}>
            <Link to={splat} aria-label={title}>{decodeEntities(title)}</Link>
          </li>
        );
      })}
    </ul>
  );
}

export const FooterNav = (props) => {
  const { baseUrl, navItems } = props;
  if (!navItems) return null;
  const navCols = [];
  let currCol = [];
  for (let i = 0; i < navItems.length; i+=1) {
    if (navItems[i].classes === 'col' && i !== 0) {
      navCols.push(currCol);
      currCol = [];
    }
    currCol.push(navItems[i]);
  }
  if (currCol) navCols.push(currCol);
  return (
    <nav className="footer-nav">
      <div className="nav-cols">
        {navCols && navCols.map((col, index) => (
          <div className="nav-col" key={index}>
            {col.map(item => (
              <NavItem key={item.url} item={item} baseUrl={baseUrl} />
            ))}
          </div>
        ))
      }
      </div>
    </nav>
  );
}
