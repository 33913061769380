import React, { Component, Fragment } from 'react';
import { Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import './HeaderNav.scss'

const enableBodyScroll = () => {
  document.body.classList.remove('nav-open');
}

const NavItem = (props) => {
  const { item, baseUrl, openNavKey, toggleSubNav } = props;
  const {
    classes,
    slug,
    title,
    url,
    children
  } = item;
  const splat = url.replace(baseUrl, '');
  const isOpen = openNavKey === slug;
  const isExternal = classes === 'external';
  return (
    <div
      key={slug}
      className={`nav-link ${classes} ${children ? 'parent-link' : ''}`}
    >
      {isExternal ? (
        <a href={url} aria-label={title} target="_blank" rel="noopener noreferrer">{decodeEntities(title)}</a>
      ) : (
        <Link to={splat} onClick={enableBodyScroll} aria-label={decodeEntities(title)}>{decodeEntities(title)}</Link>
      )}
      {children && (
        <Fragment>
          <NavChildren baseUrl={baseUrl} childNav={children} isOpen={isOpen} />
          <button
            type="button"
            className={`nav-expand ${isOpen ? 'open' : 'closed'}`}
            onClick={event => toggleSubNav(event, slug)}
            aria-label="Open Menu"
          >
            {isOpen ? 'Close' : 'Open'}
          </button>
        </Fragment>
      )}
    </div>
  );
}

const NavChildren = (props) => {
  const { childNav, baseUrl, isOpen } = props;
  return (
    <ul className={`nav-children ${isOpen ? 'open' : 'close'}`}>
      {childNav.map(child => {
        const {
          classes,
          slug,
          title,
          url
        } = child;
        const isExternal = classes === 'external';
        const splat = url.replace(baseUrl, '');
        return isExternal ? (
          <li key={slug} className={`nav-child ${classes}`}>
            <a href={url} aria-label={title} target="_blank" rel="noopener noreferrer">{title}</a>
          </li>
        ) : (
          <li key={slug} className={`nav-child ${classes}`}>
            <Link to={splat} onClick={enableBodyScroll} aria-label={title}>{title}</Link>
          </li>
        );
      })}
    </ul>
  );
}

// const SERVICES_TYPE = 'services-nav';
// const COMMUNCIATIONS_TYPE = 'communication-nav';
// const COMPANY_TYPE = 'company-nav';

export default class HeaderNav extends Component {
  state = { openNavKey: null };

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscKey, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscKey, false);
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  }

  render() {
    const { active, navItems, baseUrl } = this.props;
    const { openNavKey } = this.state;
    const navCols = [];
    let currCol = [];
    for (let i = 0; i < navItems.length; i+=1) {
      if (navItems[i].classes === 'col' && i !== 0) {
        navCols.push(currCol);
        currCol = [];
      }
      currCol.push(navItems[i]);
    }
    if (currCol) navCols.push(currCol);
    return (
      <nav className={`header-nav ${active ? 'active' : ''}`}>
        <div className="wrap">
          <div className="nav-cols">
            {navCols && navCols.map((col, index) => (
              <div className="nav-col" key={index}>
                {col.map(item => (
                  <NavItem key={item.url} item={item} baseUrl={baseUrl} toggleSubNav={this.toggleSubNav} openNavKey={openNavKey} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </nav>
    );
  }
}
