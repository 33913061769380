import React, { Component } from 'react';
import './HeaderForm.scss';

export default class HeaderForm extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleEscKey, false);
  }

  componentWillReceiveProps(nextProps) {
    const { active: wasActive } = this.props;
    const { active: isActive } = nextProps;
    if (!wasActive && isActive) {
      setTimeout(() => {
        if (this.firstInput) this.firstInput.focus();
      }, 100);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscKey, false);
  }

  handleEscKey = (event) => {
    const { toggleContact, active } = this.props;
    if (event.keyCode === 27 && active) toggleContact(event);
  }

  render() {
    const { active, siteSettings, toggleContact, siteUrl } = this.props;
    const { options } = siteSettings;
    const {
      addressDisplay,
      phoneNumber,
      url
    } = options;
    if (!active) return <div className="header-form-block" />;
    return (
      <div className="header-form-block active">
        <div className="wrap">
          <button
            type="button"
            className="hamburger active"
            onClick={toggleContact}
            aria-label="Close Contact Form"
          />
          <div className="text-col">
            <span className="col-title">Contact Details</span>
            <div className="details-group">
              <a href={`tel:${phoneNumber}`}>{`T ${phoneNumber}`}</a>
            </div>
            <div className="address" dangerouslySetInnerHTML={{ __html: addressDisplay }} />
          </div>
          <div className="form-col">
            <span className="col-title">Online Enquiry</span>
            <form
              className="header-form"
              name="header-form"
              method="post"
              action="https://api.formbucket.com/f/buk_ItuTelreUYfvrPb0N4kHf3HA"
              onSubmit="return validateForm()"
            >
              <input name="thank_you_url" value={`${siteUrl}/thank-you?query=default`} type="hidden" />
              <input type="hidden" name="form-name" value="header-enquiry" />
              <div className="form-cols">
                <div className="form-col">
                  <input type="text" id="name" name="name" placeholder="Name" ref={(c) => { this.firstInput = c; }} required />
                  <input type="email" id="email" name="email" placeholder="Email" required />
                  <input
                    type="tel"
                    pattern="^\+?\d{0,13}"
                    id="phone"
                    name="phone"
                    placeholder="Phone"
                    required
                  />
                </div>
                <div className="form-col">
                  <select id="contact-preference" name="contact-preference" placeholder="Preferred method of contact">
                    <option value="" disabled selected required>Preferred method of contact</option>
                    <option value="phone">Phone</option>
                    <option value="email">Email</option>
                  </select>
                  <textarea id="message" name="message" placeholder="Tell us a bit about yourself and how we can help" rows="3" />
                  <input type="text" name="formbucket" placeholder="Form Bucket" className="formbucket-field" />
                </div>
              </div>
              <div className="buttons">
                <button className="styled-button" type="submit" aria-label="Get in touch">
                  <span>Get in touch</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
