import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from './Logo';
import HeaderNav from './HeaderNav';
import HeaderForm from './HeaderForm';
import { EnquiryButton } from './EnquiryButton';
import SearchForm from './SearchForm';
import { SearchIcon } from './SearchIcon';
import './Header.scss';

class Header extends Component {
  state = {
    searchActive: false,
    contactActive: false,
    navActive: false,
  }

  toggleSearch = event => {
    const { searchActive } = this.state;
    if (event) event.preventDefault();
    if (!searchActive) {
      this.closeAll();
      this.setState({ searchActive: true });
    } else {
      this.setState({ searchActive: false });
    }
  };

  toggleContact = event => {
    const { contactActive } = this.state;
    if (event) event.preventDefault();
    if (!contactActive) {
      this.closeAll();
      this.setState({ contactActive: true });
      document.body.scrollIntoView({ block: 'start' });
    } else {
      this.setState({ contactActive: false });
    }
  };

  toggleNav = event => {
    const { navActive } = this.state;
    if (event) event.preventDefault();
    if (!navActive) {
      document.body.classList.add('nav-open');
      this.setState({
        contactActive: false,
        navActive: true,
        searchActive: false,
      });
    } else {
      document.body.classList.remove('nav-open');
      this.setState({ navActive: false });
    }
  };

  closeAll = event => {
    if (event) event.preventDefault();
    document.body.classList.remove('nav-open');
    this.setState({
      searchActive: false,
      contactActive: false,
      navActive: false,
    });
  };

  render() {
    const {
      searchActive,
      contactActive,
      navActive,
    } = this.state;
    const {
      data,
    } = this.props;
    const {
      primaryNavigation,
      siteSettings,
      metaData,
      site: {
        siteMetadata: {
          siteUrl
        }
      }
    } = data;
    const { url: baseUrl } = metaData;
    const { items: navItems } = primaryNavigation;
    return (
      <header>
        <HeaderForm
          active={contactActive}
          siteSettings={siteSettings}
          toggleContact={this.toggleContact}
          siteUrl={siteUrl}
        />
        <EnquiryButton className={contactActive ? 'hidden' : ''} toggleContact={this.toggleContact} />
        <SearchForm
          active={searchActive}
          toggleSearch={this.toggleSearch}
        />
        <div className="header-main">
          <div className="wrap">
            <Logo />
            <div className="controls">
              <button
                type="button"
                className={`control-contact icon-phone ${contactActive ? 'active' : ''}`}
                onClick={this.toggleContact}
                aria-label="Open Contact Form"
              >
                <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" stroke="#FFFFFF">
                  <path d="M22.27 23.037c-.16.153-.317.3-.466.446-.73.734-2.656.66-4.906-.188-2.777-1.047-5.809-3.13-8.544-5.863C5.621 14.7 3.54 11.666 2.492 8.889c-.848-2.25-.923-4.176-.192-4.91.15-.145.298-.301.45-.465.766-.805 1.628-1.711 2.585-1.711h.062c.672.027 1.4.52 2.158 1.46C9.82 6.08 9.02 6.87 7.69 8.174l-.256.25c-1.06 1.064.022 3.134 3.41 6.52 1.37 1.368 2.547 2.38 3.503 3 1.375.895 2.387 1.032 3.012.407l.258-.258c1.304-1.324 2.093-2.121 4.906.14.941.759 1.433 1.48 1.461 2.157.04.98-.89 1.867-1.715 2.648m.754-5.425c-3.379-2.715-4.582-1.496-5.977-.08l-.254.255c-.418.418-1.44-.145-2.01-.515-.908-.59-2.045-1.563-3.372-2.895C7.328 10.299 7.77 9.225 8 8.994l.25-.25c1.417-1.394 2.644-2.601-.075-5.98C7.257 1.619 6.358 1.04 5.43 1.002 4.085.955 3.07 2.018 2.171 2.962c-.149.157-.293.31-.434.45-.984.985-.984 3.137.005 5.758 1.086 2.879 3.235 6.016 6.047 8.828 2.813 2.813 5.944 4.957 8.828 6.043 1.312.496 2.507.746 3.5.746.984 0 1.765-.246 2.254-.734.14-.141.293-.285.45-.434.944-.898 2.014-1.918 1.96-3.261-.035-.93-.614-1.825-1.758-2.746" strokeWidth=".8" fillRule="evenodd" />
                </svg>
              </button>
              <button
                type="button"
                className={`control-search icon-search ${searchActive ? 'active' : ''}`}
                onClick={this.toggleSearch}
                aria-label="Toggle Search"
              >
                <SearchIcon strokeColor="#FFFFFF" />
              </button>
              <button
                type="button"
                className={`control-nav icon-hamburger ${navActive ? 'active' : ''}`}
                onClick={this.toggleNav}
                aria-label="Toggle Menu"
              />
            </div>
          </div>
        </div>
        <HeaderNav active={navActive} navItems={navItems} baseUrl={baseUrl} toggleNav={this.toggleNav} />
      </header>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        primaryNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "primary-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            addressDisplay,
            emailAddress,
            phoneNumber,
            url
          }
        },
        metaData: wordpressSiteMetadata {
          url
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
